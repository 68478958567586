import React from "react";
import { graphql } from "gatsby";
import { Search } from "../../../plugins/gatsby-parallelpublicworks/src/components";

function SearchPage({ data }) {
  return <Search data={data} />;
}

export default SearchPage;
export const query = graphql`
  {
    allEntityRef(filter: { private: { eq: true } }) {
      edges {
        node {
          id
          slug
        }
      }
    }
  }
`;
